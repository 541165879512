//
// Co3
//
// Copyright:: (c) 2025 Innotronic Ingenieurbüro GmbH

import { Controller } from '@hotwired/stimulus';
import { patch } from '@rails/request.js'

export default class extends Controller
{
  static values = { updateUrl: String };

  initialize()
  {
    this.update = this.update.bind( this );
  }

  connect()
  {
    this.turbo_frame = this.element.closest( 'turbo-frame' );
    this.form = this.element.closest( 'form' )
  }

  async update( e )
  {
    const data = new FormData( this.element );
    const response = await patch( this.url, { body: data, responseKind: 'turbo_stream' } );
  }

  get url()
  {
    if( this.hasUpdateUrlValue )
    {
      return this.updateUrlValue;
    }
    else if( this.turbo_frame && this.turbo_frame.hasAttribute( 'src' ))
    {
      return this.turbo_frame.getAttribute( 'src' );
    }
    else if( this.form.action )
    {
      return this.form.action;
    }
    else
    {
      return window.location.href;
    }
  }
}
