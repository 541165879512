//
// Co3
//
// Copyright:: (c) 2023 Innotronic Ingenieurbüro GmbH

import { Controller } from '@hotwired/stimulus';
import SlimSelect from 'slim-select';

export default class extends Controller
{
  static values = { addable: Boolean };

  connect()
  {
    let options =
    {
      select: this.element,
      settings: {},
      events: {}
    }

    this.modal = this.element.closest( '.modal' );

    if( this.modal )
    {
      options.settings.contentLocation = this.modal;
    }

    if( this.addableValue )
    {
      options.events.addable = this.add;
    }

    this.ss = new SlimSelect( options );
  }

  disconnect()
  {
    this.ss.destroy();
    this.ss = null;
  }

  focus( e )
  {
    this.ss.open();
  }

  add( value )
  {
    return value;
  }
}